@import url(https://fonts.googleapis.com/css2?family=EB+Garamond&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Rouge+Script&display=swap);
/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {

}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
  border: 1px solid #bbbbbb;
  text-align: left;
  padding: 8px;
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ReactVirtualized__Table__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: #bbbbbb;
  border-width: 1px 1px 0 1px;
  border-style: solid;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: flex;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}

.contact-us{
  background: linear-gradient(to right, #4286f4 40%, #42e2f4 100%);
  background-image: linear-gradient(to right, rgb(66, 134, 244) 40%, rgb(0, 0, 0) 100%);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  background-size: cover;
  background-attachment: fixed;
  position: relative; 
  
}

.contact-us.overlay:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; 
  /* font-weight:  */
}
 

body {
  background-color: #3a6a9e;
  font-size: 1rem !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace; */
}

/* [class*="col-"] {
  padding-right: 2px;
  padding-left: 2px;
  margin-right: 5px;
  margin-left: 5px;
} */

@media all and (display-mode: standalone) {
  body {
    background-color: "#eee";
  }
}
.nav {
  text-decoration: none;
  border: none;
  text-align: center;
  color: white;
  list-style-type: none;
  padding: 7px;
}

.nav:hover {
  text-decoration: none;
  color: white;
}

.active {
  background-color: #0a1d40;
  border: 0.2px solid #f8f8f8;
  /* padding: .5em 1em; */
  border-radius: 0.5rem;
  color: #f8f8f8;
}

.module-pic {
  display: none;
}

.nav-right {
  float: left;
}

.spcnm::-webkit-inner-spin-button,
.spcnm::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (min-width: 1024px) {
  .module-pic {
    display: inline;
  }
}

.pointer {
  cursor: pointer !important;
}

.login-form {
  width: "100%";
  max-width: 400px;
  padding: 15px;
  margin: auto;
  height: "100%";
  padding-top: 15vh;
  position: absolute;
  top: 20px;
  right: 40%;
}

.sub {
  padding: 10px;
}

.content-container {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* display: -webkit-box;
  display: -ms-flexbox; */
  display: flex;
  /* -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center; */
  /* justify-content: center; */
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,45484d+100 */
  background: rgb(
    23,
    60,
    76
  ); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(23, 60, 76, 1) 0%,
    rgba(69, 72, 77, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#173c4c', endColorstr='#45484d',GradientType=1 ); /* IE6-9 */
}

.content-container {
  display: block;
  position: relative;
  /* padding: 50px 15px; */
  width: 100%;
}

.content-holder {
  background: rgb(23, 60, 76);
  display: block;
  width: 100%;
  /* max-width: 650px; */
  margin: 0 auto;
  /* border: 10px solid; */
  /* -o-border-image: -o-linear-gradient(45deg, #45484d, #000, #666) 1; */
  /* border-image: linear-gradient(45deg, #45484d, #000, #666) 1; */
}

.content-holder .login_p {
  font-size: 13px;
  margin: 0;
  line-height: 1;
  display: block;
  text-align: justify;
  position: relative;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-image: url(https://images.unsplash.com/photo-1559913929-08b6989ec45e?crop=entropy&cs=srgb&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzNzIzOTk3Nw&ixlib=rb-1.2.1&q=85);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

.sign_b {
  position: absolute;
  bottom: 41%;
  right: 30px;
  font-size: 25px;
  color: #fff;
  font-family: "Rouge Script", cursive;
  line-height: 1;
  font-weight: 800;
}

.sign_bd {
  font-size: 25px;
  color: #fff;
  font-family: "roboto", cursive;
  line-height: 1;
  font-weight: 800;
  text-align: center;
  padding-top: 10px;
}

